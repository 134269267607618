<template>
    <div class="box">
        <div class="concept">
            <h1>Conceito</h1>
            <p>
                De acordo com pesquisa realizada em 2020, pela Unesco, um em cada três
                alunos em todo o mundo foi vítima de bullying. Devido a essa problemática
                tão relevante procure entender sobre o Bullying.
            </p>

            <p>
                <strong>Bullying:</strong> todo ato de violência física ou psicológica,
                intencional e repetitivo que ocorre sem motivação evidente, praticado por
                indivíduo ou grupo, contra uma ou mais pessoas, com o objetivo de
                intimidá-la ou agredi-la, causando dor e angústia à vítima, em uma relação
                de desequilíbrio de poder entre as partes envolvidas.
            </p>

            <p>
                <strong>Três características</strong> precisam estar presentes na agressão
                para ser tipificado como bullying: o ato de violência (físico, verbal ou
                social), a repetição/recorrência e a relação desigual de poder, mesmo que
                ela não seja real, mas percebida pela vítima.
            </p>

            <p>
                <strong>O termo Bullying</strong> surgiu na pesquisa do professor Dan
                Olweus, o qual desenvolveu os primeiros critérios para identificar o
                fenômeno. A definição inicial de bullying trata de ações negativas ou
                agressivas praticadas por um ou mais alunos, repetidamente e ao longo do
                tempo, com um certo desequilíbrio de poder e força entre os envolvidos,
                onde o aluno exposto a essas ações têm dificuldades em se defender.
            </p>

            <p>
                <strong>TEmoção e Bullying: As emoções representam</strong> aspectos que,
                numa perspectiva pessoal, podem ajudar a esclarecer os efeitos
                prejudiciais exercidos pelas agressões sobre vítimas e agressores
                envolvidos no bullying. Então preste atenção em suas emoções e analise se
                existe correlação com algum ato ou evento que você tem vivenciado.
                Lembre-se da importância de denunciar.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ConceptBullying'
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .concept h1 {
        margin-bottom: 20px;
    }

    .concept p {
        font-size: 20px;
        text-align: justify;
        letter-spacing: 0em !important;
    }
</style>
